import { useEffect, useState } from 'react';
import { Button, Tab, Tabs } from 'react-bootstrap';
import { useHistory } from 'react-router';
import { AddIcon } from '../../components/add-icon/add-icon';
import { ConfirmDeleteUserModal } from '../../components/confirm-delete-user-modal/confirm-delete-user-modal';
import { IUser } from '../../models/IUser';
import { deleteUser, getUsers, insertUser } from '../../services/api';
import { isAdmin } from '../../utils/ref-util';
import './users-page.scss';

export const UsersPage = () => {
    const history = useHistory();
    const [users, setUsers] = useState<IUser[]>([]);
    const [filter, setFilter] = useState<string>('administrator');
    const [username, setUsername] = useState<string>('');
    const [activeUsername, setActiveUsername] = useState<string>('');
    const [showConfirmDeleteUserModal, setShowConfirmDeleteUserModal] = useState<boolean>(false);
    const [activeUserId, setActiveUserId] = useState<string>('');
    const [password, setPassword] = useState<string>('');
    const [repassword, setRepassword] = useState<string>('');
    const [firstName, setFirstName] = useState<string>('');
    const [lastName, setLastName] = useState<string>('');
    const [company, setCompanyName] = useState<string>('');
    const [email, setEmail] = useState<string>('');
    const [role, setRole] = useState<string>('');
    const [error, setError] = useState<string>('');
    const [showAddUserForm, setShowAddUserForm] = useState<boolean>(false);
    
    const getAllUsers = async (value) => {
        var result = await getUsers(value);
        setUsers(result);
    };

    useEffect(() => {
        getAllUsers(filter);
    }, []);

    if (!isAdmin()) {
        document.location.href = "/";
    }
    const TabContent = () => 
        <>
        <br/>
            {users.map((entry, index) => (
                <div className="user-item">
                    <div>
                        {entry.firstName ? `${entry.firstName} ${entry.lastName} (${entry.username})` : entry.username}
                    </div>
                    {entry.companyName ? <div>Company: {entry.companyName}</div> : <></>}
                    {entry.email ? <div>Email: {entry.email}</div>: <></>}
                    <div className="user-item-actions">
                        <a href="#" onClick={() => {
                           // history.push(`/survey/${entry.id}/edit`)
                            alert('not implemented');
                        }}>Editar</a> 
                        <a href="#" onClick={() => {
                            setActiveUsername(entry.username || "");
                            setActiveUserId(entry.id || "");
                            setShowConfirmDeleteUserModal(true);
                        }}>Eliminar</a> 
                    </div>
                    <br/>
                </div>
            ))}
        </>;

    return (
        <div className="users-page">
            <div style={{cursor: 'pointer', color: '#33ACE1'}} onClick={() => { setShowAddUserForm(true)}}>
                <AddIcon></AddIcon>&nbsp;Agregar cliente ó usuario<br/><br/>
            </div>
            {
                showAddUserForm ? 
                <div>
                    <div>
                        <label>Usuario:</label><br/>
                        <input  
                            className="add-user-row"
                            autoComplete="off"
                            onChange={(e) =>{ setUsername(e.target.value)} }
                            onBlur={(e) => { setUsername(e.target.value) } }
                        />
                    </div>
                    <div>
                        <label>Contraseña:</label><br/>
                        <input  
                            autoComplete="off"
                            className="add-user-row"
                            type="password"
                            onChange={(e) =>{ setPassword(e.target.value)} }
                            onBlur={(e) => { setPassword(e.target.value) } }
                        />
                    </div>
                    <div>
                        <label>Repita contraseña:</label><br/>
                        <input  
                            autoComplete="off"
                            className="add-user-row"
                            type="password"
                            onChange={(e) =>{ setRepassword(e.target.value)} }
                            onBlur={(e) => { setRepassword(e.target.value) } }
                        />
                    </div>
                    <div>
                        <label>Primer Nombre:</label><br/>
                        <input  
                            className="add-user-row"
                            onChange={(e) =>{ setFirstName(e.target.value)} }
                            onBlur={(e) => { setFirstName(e.target.value) } }
                        />
                    </div>
                    <div>
                        <label>Apellido:</label><br/>
                        <input  
                            className="add-user-row"
                            onChange={(e) =>{ setLastName(e.target.value)} }
                            onBlur={(e) => { setLastName(e.target.value) } }
                        />
                    </div>
                    <div>
                        <label>Compañia:</label><br/>
                        <input  
                            className="add-user-row"
                            onChange={(e) =>{ setCompanyName(e.target.value)} }
                            onBlur={(e) => { setCompanyName(e.target.value) } }
                        />
                    </div>
                    <div>
                        <label>Email:</label><br/>
                        <input  
                            className="add-user-row"
                            onChange={(e) =>{ setEmail(e.target.value)} }
                            onBlur={(e) => { setEmail(e.target.value) } }
                        />
                    </div>
                    <div>
                        <label>Rol:</label><br/>
                        <select aria-label="Seleccciona" onChange={(e) => { 
                            setRole(e.target.value); 
                            setFilter(e.target.value || "administrator");
                            getAllUsers(e.target.value || "administrator");
                        }}>
                            <option value="">Selecciona</option>
                            <option value="administrator">Administrador</option>                            
                            <option value="client">Cliente</option>
                            <option value="ambassador">Embajador</option>
                        </select>
                    </div>
                    <br/>
                    <Button variant="primary" type="submit" onClick={(ev) => {
                        if (!username) {
                            setError('Introduce un nombre de usuario');
                            ev.preventDefault();
                            return;
                        }

                        if (password !== repassword) {
                            setError('Las contraseñas no coinciden');
                            ev.preventDefault();
                            return;
                        }

                        if (!role) {
                            setError('Selecciona un rol');
                            ev.preventDefault();
                            return;
                        }

                        insertUser({
                            username: username,
                            password: password,
                            firstName: firstName,
                            lastName: lastName,
                            companyName: company,
                            role: role,
                            email: email
                        }).then((response) => {
                            if (response === 'Success') {
                                setShowAddUserForm(false);
                                getAllUsers(filter);
                            } else if (response === 'Duplicate') {
                                setError('Ya existe una cuenta con ese mismo usuario. Intente otro diferente.');
                            } else {
                                setError('No pudimos agregar ese usuario. Contacte su administrador')
                                console.log("insertUser", response);
                            }        
                        });
                        ev.preventDefault();
                    }}>{'Agregar'}</Button>
                    <Button style={{marginLeft:15}} type="submit" onClick={() => {
                        setError('');
                        setShowAddUserForm(false);
                     }}>Cancelar</Button>
                    <br/><br/>
                    <div className="add-user-error">{error}</div><br/>
                </div> :
                <></>
            }
            <Tabs activeKey={filter} onSelect={(value) => { setFilter(value || ""); setUsers([]); getAllUsers(value);}}>
                <Tab eventKey="administrator" title={'Administradores'}>
                    <TabContent/>
                </Tab>
                <Tab eventKey="ambassador" title={'Embajadores'}>
                    <TabContent/>
                </Tab>
                <Tab eventKey="client" title={'Clientes'}>
                    <TabContent/>
                </Tab>
            </Tabs>
            <ConfirmDeleteUserModal 
                username={activeUsername}
                show={showConfirmDeleteUserModal}
                onAccept={() => {
                    deleteUser({id: (activeUserId)}).then((result) => {
                        if (result.code === "0") {
                            getAllUsers(filter);
                        } else {
                            setError("No pudimos eliminar ese usuario.")
                        }
                    });
                    setShowConfirmDeleteUserModal(false);
                }}  
                onHide={()=> {
                    setShowConfirmDeleteUserModal(false);
                }}/>
        </div>
    );
}

