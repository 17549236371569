import { Trans } from '@lingui/macro';
import { I18nProvider } from '@lingui/react';
import classNames from 'classnames';
import React, { useEffect } from 'react';
import { useState } from 'react';
import ReactDOM from 'react-dom';
import { Provider, useSelector } from "react-redux";
import { BrowserRouter as Router, Link, Route, Switch, useHistory } from "react-router-dom";
import PullToRefresh from 'react-simple-pull-to-refresh';
import { LandingPage } from './pages/landing-page/landing-page';
import ScrollToTop from '../src/utils/scroll-to-top';
import { EditSurveyPage } from './pages/edit-survey-page/edit-survey-page';
import './bootstrap.scss';
import { Login } from './components/login/login';
import { TopBar } from './components/top-bar/top-bar';
import { activateLocale } from './config/locales';
import { i18n } from '@lingui/core';
import useLocale from './hooks/useLocale';
import './index.scss';
import reportWebVitals from './report-web-vitals';
import { login } from './services/api';
import { store } from "./store";
import { SurveyResultsPage } from './pages/survey-results-page/survey-results-page';
import { SurveyPage } from './pages/survey-page/survey-page';
import { isEmbedMode } from './utils/navigator-util';
import { UsersPage } from './pages/user-page/users-page';
import { handleLogin, isLoggedIn } from './utils/login-util';
import { SurveyRespondentsPage } from './pages/survey-respondents-page/survey-respondents-page';

const App = () => {
    const history = useHistory();
    const [locale] = useLocale();
    const isEmbed = isEmbedMode();

    const [isLogin, setIsLogin] = useState(isLoggedIn());
    const [error, setError] = useState('');

    if (document.location.hash) {
        history.replace(`/${document.location.hash.substr(1)}`);
    }

    const onLogin = (username: string, password: string) =>{
        if (!username || !password) {
            setError('Introduzca su usuario y contraseña');
            return;
        }
        login(username, password).then((resp: { code: number, token: string, userId: number, ref: number}) => {
            setIsLogin(resp.code === 0);
            if (resp.code === 0) {
                handleLogin(resp.userId, username, resp.token, resp.ref);
            } else if (resp.code === 1) {
                setError('El usuario es invalido');
            } else if (resp.code === 2) {
                setError('La contraseña es invalida');
            } else {
                setError('');
            }
        });
    };

    const updateHtmlBackgroundColor = (darkMode: boolean) => {
        // Adding height: 100% to the <html> tag was having side effects; however, 
        // we want the background color to expand to <html>, so this is a workaround 
        // to achieve that
        const htmlTag = document.querySelector('html');
        if (htmlTag) {
            htmlTag.style.backgroundColor = darkMode ? 'black' : 'transparent';
        }
    };

    useEffect(() => {
        activateLocale(locale);
        updateHtmlBackgroundColor(false);
    }, [locale]);

    const handleRefresh = (): Promise<void> => {
        return new Promise(res => {
            setTimeout(() => {
                res();
                window.location.reload();
            }, 1000);
        });
    };

    if (!isLogin && 
        !(document.location.search.indexOf("ambassadorId=")>=0)) {
        return <Login onSubmit={onLogin} error={error}></Login>;
    }
    return (
        <div className={classNames(`app lang-${locale}`, false && ' dark-mode', isEmbed && ' embed')}>
            <PullToRefresh
                onRefresh={handleRefresh}
                pullingContent=''>
                <>
                    <ScrollToTop />
                    {!isEmbed && <TopBar />}
                    <div className="page-content">
                        <Switch>
                            <Route path="/survey/add">
                                <EditSurveyPage />
                            </Route>
                            <Route path="/survey/:surveyId/answers">
                                <SurveyResultsPage/>
                            </Route>
                            <Route path="/survey/:surveyId/respondents">
                                <SurveyRespondentsPage/>
                            </Route>
                            <Route path="/survey/:surveyId/edit">
                                <EditSurveyPage />
                            </Route>
                            <Route path="/survey/:surveyId">
                                <SurveyPage/>
                            </Route>
                            <Route path="/ambassaddors">
                                <LandingPage />
                            </Route>
                            <Route path="/users">
                                <UsersPage />
                            </Route>
                            <Route path="/ua">
                                <div>{navigator.userAgent}</div>
                            </Route>
                            {/* If none of the previous routes render anything,
                                this route acts as a fallback.

                                Important: A route with path="/" will *always* match
                                the URL because all URLs begin with a /. So that's
                                why we put this one last of all */}
                            <Route path="/">
                                <LandingPage />
                            </Route>
                        </Switch>
                    </div>
                    {!isEmbed && <div className='footer'>
                        <div className="footer-links">
                            <Link to="/terms"><Trans>Terminos</Trans></Link>
                            <Link to="/privacy"><Trans>Privacidad</Trans></Link>
                        </div>
                        <h6 className='footer-content'>
                            <Trans>© 2022 encuestas.store - Todos los derechos reservados.</Trans>
                        </h6>
                    </div>}
                </>
            </PullToRefresh>
        </div>
    );
};

ReactDOM.render(
    <Provider store={store}>
        <Router>
            <I18nProvider i18n={i18n}>
                <React.StrictMode>
                    <App />
                </React.StrictMode>
            </I18nProvider>
        </Router>
    </Provider>,
    document.getElementById('root')
);


// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
