import { useEffect, useState } from 'react';
import { Button, NavDropdown } from 'react-bootstrap';
import { IRespondent } from '../../models/IRespondent';
import { IUser } from '../../models/IUser';
import { addSurveyAmbassador, deleteAnswers, deleteSurvey, getSurveyAmbassadors, getSurveyRespondents, getUsers, removeSurveyAmbassador } from '../../services/api';
import { isAdmin } from '../../utils/ref-util';
import { getEstado, getStatusPerAmbassador } from '../../utils/respondent-utils';
import { QRCodeModal } from '../qr-code-modal/qr-code-modal';
import './add-survey-ambassadors.scss';

export const AddSurveyAmbassadors = (props: {
    surveyId: string
}) => {
    const [ambassadors, setAmbassadors] = useState<IUser[]>([]);
    const [respondents, setRespondents] = useState<IRespondent[]>([]);
    const [surveyAmbassadors, setSurveyAmbassadors] = useState<IUser[] | null>(null);
    const [showQRCodeModal, setShowQRCodeModal] = useState(false);
    const [activeAmbassadorId, setActiveAmbassadorId] = useState<string>();
    const [error, setError] = useState<string>("");
    const [stats, setStats] = useState<{ ambassadorId: string, pending: number, verified: number, answeredSurvey: number, readyForGift: number}[]>([]);
    const getAllUsers = async (value) => {
        var result = await getUsers(value);
        setAmbassadors(result);
    };

    const getAmbassadorsAndRespondents = async () => {
        var result1 = await getSurveyAmbassadors(props.surveyId);
        setSurveyAmbassadors(result1);
        var results2 = await getSurveyRespondents(props.surveyId)
        setRespondents(results2);
        var stats= getStatusPerAmbassador(result1, results2);
        setStats(stats);
    }

    useEffect(() => {
        getAllUsers('ambassador');
        getAmbassadorsAndRespondents();
    }, []);

    const isUserAdmin = isAdmin();

    return (
        <div className="add-survey-ambassadors">
            <div>
                <span style={{marginRight:10}}>Asignar embajador:</span>
                <select style={{marginRight:5, marginBottom:5}}
                    onChange={(e) => { setActiveAmbassadorId(e.target.value)}}
                >
                    <option value="">Selecciona</option>
                    {ambassadors?.map((entry, index) => {
                        return <option key={entry.id} value={entry.id}>{entry.firstName ? `(${entry.username}) - ${entry.firstName} ${entry.lastName}`: entry.username}</option>
                    })}
                </select>
                <Button className="add-ambassador-button" variant="primary" type="submit" onClick={(ev) =>{
                    setError('');
                    addSurveyAmbassador(props.surveyId, activeAmbassadorId || "").then((result) => {
                        if (result === 'Success') {
                            getAmbassadorsAndRespondents();
                        } else {
                            console.log(result);
                            setError("No pudimos asignar ese embajador")
                        }
                    })
                }}>Asignar</Button>
                <div className="add-ambassador-error">{error}</div>
            </div>
            {surveyAmbassadors && surveyAmbassadors?.length === 0 ? <div style={{marginTop:40}}>Ningun embajador a sido asignado</div> : <></>}
            {surveyAmbassadors && surveyAmbassadors?.length > 0 ? <><table className="ambassador-state-stats">
                <tr className="ambassador-state-stats-default-header">
                    <th>Embajador</th>
                    <th>Peticiones</th>
                    <th>Verificadas</th>
                    <th>Proceso</th>
                    <th>Finalizadas</th>
                </tr>
                <tr className="ambassador-state-stats-mobile-header">
                    <th>Embajador</th>
                    <th>Pet.</th>
                    <th>Ver.</th>
                    <th>Pro.</th>
                    <th>Fin.</th>
                </tr>
                {
                stats.map((entry) => 
                     (
                        <tr>
                            <td>{entry.ambassadorId}</td>
                            <td>{entry.pending}</td>
                            <td>{entry.verified}</td>
                            <td>{entry.answeredSurvey}</td>
                            <td>{entry.readyForGift}</td>
                        </tr>
                    )
                )}
            </table><div className="ambassador-state-stats-mobile-legend">Pet: Peticiones<br/>Ver: Verificadas<br/>Pro: Proceso<br/>Fin: Finalizadas</div></> : <></>}
            <div className="ambassadors-assigned">
                <br/><br/>
                {surveyAmbassadors?.map((entry, index) => (
                    <div key={`u-${entry.id}`} className="user-item">
                        <div style={{fontWeight:'bold'}}>
                            {entry.firstName ? `${entry.firstName} ${entry.lastName} (${entry.username})` : entry.username}
                        </div>
                        {entry.companyName ? <div>Company: {entry.companyName}</div> : <></>}
                        {entry.email ? <div>Email: {entry.email}</div>: <></>}
                        <div className="user-item-actions">
                            <a href="#" onClick={() => {
                                setActiveAmbassadorId(entry.ambassadorId || "");
                                // TODO: show delete modal
                                removeSurveyAmbassador(entry.id || "").then(() => {
                                    getAmbassadorsAndRespondents();
                                });
                            }}>Eliminar</a>
                            {isUserAdmin ? <a href="#" onClick={() => {
                                    setActiveAmbassadorId(entry.ambassadorId || "");
                                    setShowQRCodeModal(true);
                                }}>QR Code</a> : <></>}
                            {isUserAdmin ? <a href="#" onClick={() => {
                                    var clipboardData = {};
                                    var link = `${document.location.protocol}//${document.location.host}/survey/${props.surveyId}?ambassadorId=${entry.ambassadorId}`;
                                    clipboardData["text/plain"] = new Blob([link], { type: 'text/plain' });
                                    var clipboardItem = new window['ClipboardItem'](clipboardData);
                                    navigator.clipboard['write']([clipboardItem]).then(function() {
                                        alert('Enlace fue copiar al portapapeles: ' + link)
                                    }, function() {
                                    console.error("Unable to write to clipboard. :-(");
                                    });
                                }}>Copiar enlace</a> : <></>}
                        </div>
                        <br/>
                        {respondents?.filter((respondent) => { return respondent.ambassadorId == entry.ambassadorId }).length > 0 ? <table>
                            <tr>
                                <th>Id y Nombre</th>
                                <th style={{minWidth:140}}>Celular y Código</th>
                                <th>Estado</th>
                            </tr>
                        {respondents?.filter((respondent) => { return respondent.ambassadorId == entry.ambassadorId }).map((entry, index) => {
                            return (
                                <tr>
                                    <td className="respondent-name">{entry.respondentId} - {entry.name}</td>
                                    {entry.phone !== '-1' ? <td>
                                        <div style={{float:'left'}}>{entry.phone}<br/>
                                        <span>Código: </span>{entry.verifyCode}</div>
                                        <div style={{paddingLeft:5,marginTop:-13, height:24,paddingRight:4,width:10}}>
                                        <NavDropdown title={''} style={{fontSize:24, color: 'silver'}}>
                                            {isUserAdmin && <NavDropdown.Item key={`key${entry.phone}`} href="#" onClick={async () => {
                                                await deleteAnswers(props.surveyId, entry.phone || '');
                                                await getAmbassadorsAndRespondents();
                                            }}>Eliminar</NavDropdown.Item>}
                                        </NavDropdown>
                                        </div>
                                  
                                    </td> : <td>
                                        <div style={{float:'left', marginRight:5}}>Ninguno</div>
                                        <div style={{paddingLeft:5,marginTop:-13, height:24,paddingRight:4,width:10}}>
                                        <NavDropdown title={''} style={{fontSize:24, color: 'silver'}}>
                                            {isUserAdmin && <NavDropdown.Item key={`key${entry.phone}`} href="#" onClick={async () => {
                                                await deleteAnswers(props.surveyId, entry.phone || '');
                                                await getAmbassadorsAndRespondents();
                                            }}>Eliminar</NavDropdown.Item>}
                                        </NavDropdown>
                                        </div>
                                    </td>}
                                    <td>{getEstado(entry.status || '')}</td>
                                </tr>
                            )
                        })}
                        </table>: <></>}
                    </div>))}
            </div>
            <QRCodeModal
                 surveyId={props.surveyId}
                 ambassadorId={(activeAmbassadorId || "")}
                 show={showQRCodeModal}
                 onAccept={() => {
                     setShowQRCodeModal(false);
                 }}
                 onHide={()=> {
                     setShowQRCodeModal(false);
                 }}/>
        </div>
    );
}