import { request } from "https";
import { useEffect, useState } from "react";
import { Button } from "react-bootstrap";
import { useParams } from "react-router";
import { AcceptConditionsModal } from "../../components/accept-conditions-modal/accept-conditions-modal";
import { SurveyQuestion } from "../../components/survey-question/survey-question";
import { IAnswer } from "../../models/IAnswer";
import { IQuestion } from "../../models/IQuestion";
import { IRawQuestion } from "../../models/IRawQuestion";
import { ISurvey } from "../../models/ISurvey";
import { getSurvey, getSurveyQuestions, submitSurvey, updateRespondent, verify } from "../../services/api";
import { convertRawQuestions } from "../../utils/questions-util";
import { getUrlParameter } from "../../utils/url-utils";
import './survey-page.scss';

const numberRe = /^[0-9\b]+$/;

export const SurveyPage = () => {
    let { surveyId } = useParams<{ surveyId: string }>();
    let ambassadorId = getUrlParameter("ambassadorId");
    const [respondentId, setRespondentId] = useState<string>(getUrlParameter("respondentId"));
    const [questions, setQuestions] = useState<IQuestion[]>([]);
    const [rawQuestions, setRawQuestions] = useState<IRawQuestion[]>([]);
    const [countryCode, setCountryCode] = useState<string>("57");
    const [error, setError] = useState<string>("");
    const [respondentUpdateError, setRespondentUpdateError] = useState<string>("");
    const [verifyCode, setVerifyCode] = useState<string>("");
    const [survey, setSurvey] = useState<ISurvey>();
    const [isVerified, setIsVerified] = useState<boolean>(!!respondentId);
    const [isDoneWithSurvey, setIsDoneWithSurvey] = useState<boolean>(false);
    const [isDoneUpdatingRespondentInfo, setIsDoneUpdatingRespondentInfo] = useState<boolean>(false);
    const [showEnterVerifyCode, setShowEnterVerifyCode] = useState<boolean>(false);
    const [phone, setPhone] = useState<string>('')
    const [isVerifyEnabled, setIsVerifyEnabled] = useState<boolean>(false);
    const [showAcceptConditionsModal, setShowAcceptConditionsModal] = useState(false);
    const [answers, setAnswers] = useState<IAnswer[]>([]);
    const [name, setName] = useState<string>('');
    const [email, setEmail] = useState<string>('');
    const [governmentId, setGovernmentId] = useState<string>('');

    const handleAcceptConditionsModalHide = () => {
        setShowAcceptConditionsModal(false);
    };

    const sendVerificationCode = () => {
        verify(`${countryCode}${phone}`, surveyId, ambassadorId).then((result)=> {
            if (result.code === 2) {
                setShowAcceptConditionsModal(false);
                setError("Ya tomaste esta encuesta. Gracias!");
            } else if (!isNaN(result.code)) {
                setError('');
                setShowEnterVerifyCode(true);
                setShowAcceptConditionsModal(false);
                if (!!result.respondentId) {
                    setRespondentId(result.respondentId);
                }
            } else {
                console.log(result);
                setError("No pudimos verificar tu número en este momento. Intenta mas tarde.")
            }
        });
    };

    const enterVerificationCode = () => { 
        if (showEnterVerifyCode) {
            if (!verifyCode) {
                setError("Tu código de verificación es inválido.");
                return;
            }
            verify(`${countryCode}${phone}`, surveyId, ambassadorId, verifyCode).then((result)=> {
                if (result.code === 0) {
                    setIsVerified(true)
                    showSurvey();
                } else if (result.code === 1) {
                    setError("Tu código de verificación es inválido.");
                } else {
                    console.log(result);
                    setError("No pudimos verificar tu número en este momento. Intenta mas tarde.")
                }
            });
        } else {
            setShowAcceptConditionsModal(true)
        }
    };

    const onPhoneChanged = (e) => {
        if ((e.target.value === '' || numberRe.test(e.target.value)) && e.target.value.length <= 10) {
            setPhone(e.target.value)
            setIsVerifyEnabled(e.target.value.length === 10)
        }
    };

    const onGovernmentIdChanged = (e) =>{ 
        if ((e.target.value === '' || numberRe.test(e.target.value)) && e.target.value.length <= 15) {
            setGovernmentId(e.target.value)
        }
    }

    const onVerifyCodeChanged = (e)=> {
        if ((e.target.value === '' || numberRe.test(e.target.value)) && e.target.value.length <= 4) {
            setVerifyCode(e.target.value)
        }
    }

    const showSurvey = async () => {
        window.scrollTo(0, 0);
        const rawQuestions = await getSurveyQuestions(surveyId);
        setQuestions(convertRawQuestions(rawQuestions));
        setRawQuestions(rawQuestions);
    };

    const removeAnswer = (questionId: string, subquestionId: string, answerId?: string) => {
        var index = -1;
        for (var i = 0; i < answers.length; i++) {
            if (answers[i].questionId === questionId &&
                answers[i].subquestionId === subquestionId &&
                (!answerId || answers[i].answerId === answerId)) {
                answers.splice(i, 1)
            }
        }
    };

    const submitRespondentUpdate = () => {
        setRespondentUpdateError('');

        if (!name && survey?.requestRespondentName) {
            setRespondentUpdateError('Introduce tu nombre para continuar');
            return;
        }

        if ((!governmentId || governmentId.length < 7) && survey?.requestRespondentGovernmentId) {
            setRespondentUpdateError('Introduce tu número de cédula para continuar');
            return;
        }

        if ((!email || email.indexOf('@') < 0 || email.indexOf('.') < 0) && survey?.requestRespondentEmail)  {
            // TODO: use an email verification regex
            setRespondentUpdateError('Introduce tu email para continuar');
            return;
        }

        var respondent = {
            id: respondentId,
            email,
            name,
            governmentId
        };
        updateRespondent(respondent).then((result) => {
            if (result === 'Success') {
                setIsDoneUpdatingRespondentInfo(true);
            } else {
                setRespondentUpdateError(`No pudimos actualizar tus datos. Contacta al embajador de la encuesta. Tu número de usuario es: ${respondentId}`);
            }
        });

    };

    const submit= () => {
        setError('');
        var hasError = false;
        for (let rawQuestion of rawQuestions) {
            var found = false;
            for (let answer of answers) {
                if (!!rawQuestion.subquestionId) {
                    if (rawQuestion.questionId === answer.questionId && rawQuestion.subquestionId === answer.subquestionId) {
                        found = true;
                    }
                } else {
                    if (rawQuestion.questionId === answer.questionId) {
                        found = true;
                    }
                }
            }
            if (!found) {
                setError(`Responde "${rawQuestion.text} ${rawQuestion.subquestion ?? ''} " para continuar.`)
                hasError = true;
                break;
            } 
        }
        if (!hasError) {
            submitSurvey(surveyId, ambassadorId, respondentId, answers).then((result) => {
                if (!respondentId) {
                    setRespondentId(result.respondentId ? result.respondentId.toString() : '');
                }
                var success = true;
                for (var i = 0; i < result.results.length; i++) {
                    if (result.results[i] !== 'Success') {
                        success = false;
                    }
                }
                if (success) {
                    window.scrollTo(0, 0);
                    if (!survey?.requestRespondentEmail && 
                        !survey?.requestRespondentGovernmentId &&
                        !survey?.requestRespondentName) {
                        setIsDoneUpdatingRespondentInfo(true);
                    } else {
                        setIsDoneWithSurvey(true);
                    }
                } else {
                    alert('Error subiendo respuestas de la encuesta');
                }
            });
        }
    };

    const onAnswerChanged = (config: {
        type: string,
        isSet: boolean,
        questionId: string, 
        subquestionId: string, 
        answerId?: string,
        text?: string;
    }) => {
        switch (config.type) {
            case 'text':
                removeAnswer(config.questionId, config.subquestionId);
                answers.push({
                    questionId: config.questionId,
                    subquestionId: config.subquestionId,
                    text: config.text
                });
                break;
            case 'singlechoice':
            case 'table':
            case 'dropdown':
                removeAnswer(config.questionId, config.subquestionId);
                answers.push({
                    questionId: config.questionId,
                    subquestionId: config.subquestionId,
                    answerId: config.answerId
                });
                break;
            case 'multiplechoice': 
                if (!config.isSet) {
                    removeAnswer(config.questionId, config.subquestionId, config.answerId);
                } else {
                    answers.push({
                        questionId: config.questionId,
                        subquestionId: config.subquestionId,
                        answerId: config.answerId
                    });
                }
                break;
        }
    };

    useEffect(() => {
        (async () => {
            var survey = (await getSurvey(surveyId))[0];
            setSurvey(survey);
        })();

        if (!!respondentId) {
            showSurvey();
        }
    }, []);

    if (!survey) {
        return <></>;
    }

    if (!ambassadorId) {
        return (
            <div>QR Code es inválido. No contiene ambassadorId.</div>
        );
    } else if (isVerified) {
        if (isDoneUpdatingRespondentInfo) {
            return (
                <div className="survey-page verified">
                    <br/>
                    <img style={{maxHeight:80}} src={survey?.photoUrl}/>
                    <br/><br/>
                    <div>{survey?.name}</div>
                    <div>Muchas gracias por responder esta encuesta!</div><br/>

                    <div>{survey?.giftDetails}</div><br/>
                </div>
            );
        } else if (isDoneWithSurvey) {
            return (
                <div className="survey-page verified">
                    <br/>
                    <img style={{maxHeight:80}} src={survey?.photoUrl}/>
                    <br/><br/>
                    <div>{survey?.name}</div>
                    <div>Muchas gracias por responder esta encuesta!</div><br/>
                    <div>Introduce la siguiente información para recibir tu obsequio:</div><br/>
                    {survey?.requestRespondentName ? <div>
                        <label>Nombre completo:</label><br/>
                        <input  
                            className="respondent-row"
                            onChange={(e) =>{ setName(e.target.value)} }
                            onBlur={(e) => { setName(e.target.value) } }
                        />
                    </div> : <></>}
                    {survey?.requestRespondentGovernmentId ? <div>
                        <label>Número de Cėdula:</label><br/>
                        <input  
                            value={governmentId}
                            className="respondent-row"
                            onChange={onGovernmentIdChanged}
                            onBlur={onGovernmentIdChanged}
                        />
                    </div> : <></>}
                    {survey?.requestRespondentEmail ? <div>
                        <label>Email:</label><br/>
                        <input  
                            className="respondent-row"
                            onChange={(e) =>{ setEmail(e.target.value)} }
                            onBlur={(e) => { setEmail(e.target.value) } }
                        />
                    </div>: <></>}
                    <div><br/>
                    <Button onClick={() => { 
                        submitRespondentUpdate();
                        }}>Finalizar</Button>
                        <div className="respondent-update-error">{respondentUpdateError}</div> 
                    </div>
                </div>
            )
        } else {

            return (
                <div className="survey-page verified">
                    <br/>
                    <img style={{maxHeight:80}} src={survey?.photoUrl}/>
                    <br/><br/>
                    {questions.map((entry, index) => (
                        <SurveyQuestion question={entry} onAnswerChanged={onAnswerChanged}></SurveyQuestion>
                    ))}

                    <Button onClick={() => { 
                        submit();
                    }}>Enviar</Button>
                    <div className="survey-error">{error}</div>
                </div>
            );
        }
    } else if (!survey.requestRespondentPhone) {
       
        return <>
            <br/><br/>
            <img style={{maxHeight:80}} src={survey?.photoUrl}/>
            <br/><br/>
            <div style={{padding:"0 10px"}}>{survey?.welcomeMessage}<br/>
            <br/>
            <Button onClick={() => {
                setIsVerified(true);
                showSurvey();
            }}>Comenzar</Button>
            </div>

        </>;
    } else {
        return (
            <div className="survey-page unverified">
                <br/>
                <span>{survey?.welcomeMessage}</span><br/><br/>
                <img style={{maxHeight:80}} src={survey?.photoUrl}/>
                <br/><br/>
                <label>Verifica tu número de teléfono:</label><br/>
                <select value={countryCode} onChange={(ev) => {
                    setCountryCode(ev.target.value);
                }} style={{minWidth: 120, float: 'left', marginRight: 5}}>
                    <option value="57">+57 - Colombia</option>
                    <option value="52">+52 - Mexico</option>
                    <option value="1">+1 - USA</option>
                </select>
                <input value={phone} type="text" className="survey-phone" onChange={onPhoneChanged}/>
                <br/><br/>
                {showEnterVerifyCode ? <>
                    <label>Introduce to código de verificación</label><br/>
                    <input value={verifyCode} onChange={onVerifyCodeChanged} style={{height: '2.5em', width: 80} } type="text"></input>
                    <br/><br/>
                </> : <></>}
                <Button disabled={!isVerifyEnabled} onClick={enterVerificationCode}>Verificar</Button>&nbsp;&nbsp;&nbsp;
                {showEnterVerifyCode ? <a href="#" onClick={sendVerificationCode}>re-enviar</a> : <></>}
                <br/><br/>
                <div className="survey-error">{error}</div>
                <AcceptConditionsModal show={showAcceptConditionsModal} onAccept={sendVerificationCode}  onHide={handleAcceptConditionsModalHide} />
            </div>
        );
    }
}
